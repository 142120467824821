// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-area-page-area-page-tsx": () => import("./../../../src/pages/AreaPage/AreaPage.tsx" /* webpackChunkName: "component---src-pages-area-page-area-page-tsx" */),
  "component---src-pages-contact-page-contact-page-tsx": () => import("./../../../src/pages/ContactPage/ContactPage.tsx" /* webpackChunkName: "component---src-pages-contact-page-contact-page-tsx" */),
  "component---src-pages-data-security-page-data-security-page-tsx": () => import("./../../../src/pages/DataSecurityPage/DataSecurityPage.tsx" /* webpackChunkName: "component---src-pages-data-security-page-data-security-page-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-en-contact-tsx": () => import("./../../../src/pages/en/contact.tsx" /* webpackChunkName: "component---src-pages-en-contact-tsx" */),
  "component---src-pages-en-imprint-tsx": () => import("./../../../src/pages/en/imprint.tsx" /* webpackChunkName: "component---src-pages-en-imprint-tsx" */),
  "component---src-pages-en-practice-tsx": () => import("./../../../src/pages/en/practice.tsx" /* webpackChunkName: "component---src-pages-en-practice-tsx" */),
  "component---src-pages-en-sports-medicine-lactatetest-tsx": () => import("./../../../src/pages/en/sports-medicine-lactatetest.tsx" /* webpackChunkName: "component---src-pages-en-sports-medicine-lactatetest-tsx" */),
  "component---src-pages-ernahrungsberatung-tsx": () => import("./../../../src/pages/ernährungsberatung.tsx" /* webpackChunkName: "component---src-pages-ernahrungsberatung-tsx" */),
  "component---src-pages-home-page-home-page-tsx": () => import("./../../../src/pages/HomePage/HomePage.tsx" /* webpackChunkName: "component---src-pages-home-page-home-page-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-imprint-page-imprint-page-tsx": () => import("./../../../src/pages/ImprintPage/ImprintPage.tsx" /* webpackChunkName: "component---src-pages-imprint-page-imprint-page-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-not-found-page-not-found-page-tsx": () => import("./../../../src/pages/NotFoundPage/NotFoundPage.tsx" /* webpackChunkName: "component---src-pages-not-found-page-not-found-page-tsx" */),
  "component---src-pages-nutrition-page-nutrition-page-tsx": () => import("./../../../src/pages/NutritionPage/NutritionPage.tsx" /* webpackChunkName: "component---src-pages-nutrition-page-nutrition-page-tsx" */),
  "component---src-pages-office-page-office-page-tsx": () => import("./../../../src/pages/OfficePage/OfficePage.tsx" /* webpackChunkName: "component---src-pages-office-page-office-page-tsx" */),
  "component---src-pages-personal-training-tsx": () => import("./../../../src/pages/personal-training.tsx" /* webpackChunkName: "component---src-pages-personal-training-tsx" */),
  "component---src-pages-praxis-tsx": () => import("./../../../src/pages/praxis.tsx" /* webpackChunkName: "component---src-pages-praxis-tsx" */),
  "component---src-pages-preise-tsx": () => import("./../../../src/pages/preise.tsx" /* webpackChunkName: "component---src-pages-preise-tsx" */),
  "component---src-pages-prices-page-prices-page-tsx": () => import("./../../../src/pages/PricesPage/PricesPage.tsx" /* webpackChunkName: "component---src-pages-prices-page-prices-page-tsx" */),
  "component---src-pages-sportmedizin-laktattest-tsx": () => import("./../../../src/pages/sportmedizin-laktattest.tsx" /* webpackChunkName: "component---src-pages-sportmedizin-laktattest-tsx" */),
  "component---src-pages-sportmedizin-tsx": () => import("./../../../src/pages/sportmedizin.tsx" /* webpackChunkName: "component---src-pages-sportmedizin-tsx" */),
  "component---src-pages-sports-med-page-sports-med-page-tsx": () => import("./../../../src/pages/SportsMedPage/SportsMedPage.tsx" /* webpackChunkName: "component---src-pages-sports-med-page-sports-med-page-tsx" */),
  "component---src-pages-sports-page-sports-page-tsx": () => import("./../../../src/pages/SportsPage/SportsPage.tsx" /* webpackChunkName: "component---src-pages-sports-page-sports-page-tsx" */),
  "component---src-pages-training-page-training-page-tsx": () => import("./../../../src/pages/TrainingPage/TrainingPage.tsx" /* webpackChunkName: "component---src-pages-training-page-training-page-tsx" */),
  "component---src-pages-trainingsbereich-tsx": () => import("./../../../src/pages/trainingsbereich.tsx" /* webpackChunkName: "component---src-pages-trainingsbereich-tsx" */)
}

